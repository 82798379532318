<template>
  <div>
    <v-dialog
      fullscreen
      value="true"
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="brandDarkCyan" rounded="0" elevation="0">
          <v-btn
            dark
            icon
            large
            @click="
              if (
                slideKey == keys.sent ||
                slideKey == keys.home ||
                slideKey == keys.failedRewards ||
                slideKey == keys.usersMissingData
              ) {
                reset();
              } else {
                confirmPageReset(true);
              }
            "
            class="ml-1"
          >
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title class="d-flex align-center font-weight-bold"
            ><v-icon v-if="!isMobile" class="mr-3">{{
              data.rewardType == "BIRTHDAY"
                ? "mdi-cake-variant"
                : "mdi-party-popper"
            }}</v-icon>
            {{ computedHeaderTitle }}</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn
            dark
            icon
            large
            @click="reset"
            class="ml-1 hidden"
            v-if="userProfile.clientId != 1"
          >
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
          <v-autocomplete
            v-else
            outlined
            dense
            hide-details
            :items="clients"
            item-text="formattedName"
            item-value="clientId"
            label="Client"
            style="max-width: 250px;"
            v-model="clientId"
            @change="refreshClientId"
            :disabled="slideKey > keys.home"
          ></v-autocomplete>
        </v-toolbar>
        <v-row
          class="full-height mt-6 pb-6 container-row"
          justify="center"
          align="center"
          height="100%"
          no-gutters
        >
          <v-col
            cols="11"
            sm="11"
            md="5"
            lg="4"
            xl="3"
            class="main-card rounded-lg px-5 d-flex flex-column justify-space-between"
          >
            <div>
              <div
                class="d-flex justify-space-between align-center mt-10"
                v-if="slideKey > keys.home && !data.errorFlow"
              >
                <pill-nav
                  :steps="pillNavSteps"
                  width="100%"
                  :height="40"
                  :step="slideKey"
                  :value="slideKey > keys.review ? progressBarValue : undefined"
                  :color="loading.submitting ? 'brandDarkCyan' : 'brandCyan'"
                  @input="goToSlide"
                >
                </pill-nav>

                <div class="lottie-container">
                  <v-btn
                    :width="isMobile ? 90 : 110"
                    rounded
                    depressed
                    color="brandCyan"
                    class="white--text font-weight-bold"
                    @click="goToNextSlide"
                    :disabled="disableContinueButton"
                  >
                    <v-icon
                      color="white"
                      class="mr-1"
                      v-if="slideKey == keys.sent"
                      >mdi-checkbox-marked-circle</v-icon
                    >
                    <v-icon
                      color="white"
                      class="mr-1"
                      v-else-if="!isMobile && slideKey != keys.review"
                      >mdi-arrow-right-circle</v-icon
                    >
                    <span
                      v-if="slideKey !== keys.sent"
                      style="margin-top:2px;"
                      >{{
                        slideKey == keys.sent
                          ? "Close"
                          : slideKey == keys.review
                          ? "Schedule"
                          : "Next"
                      }}</span
                    >
                  </v-btn>
                  <div v-if="lottie.particle" class="lottie-particle-effect">
                    <LottiePlayer
                      animation="particles"
                      :height="250"
                      :width="250"
                      :speed="1.5"
                      :loop="false"
                    />
                  </div>
                </div>
              </div>

              <transition :name="slideDirection" mode="out-in">
                <div
                  v-if="slideKey === keys.home"
                  :key="keys.home"
                  class="full-width text-left"
                >
                  <p class="text-body1-1 text-h6 my-12">
                    {{ computedHomePageHeader }}
                  </p>

                  <div
                    class="cursor-pointer rounded box-border box-border-cyan py-6 px-6 mb-6 reward-box d-flex justify-space-between"
                    @click="loadEditor('BIRTHDAY')"
                  >
                    <div class="d-flex align-center word-break">
                      <v-icon color="brandCyan" x-large class="mx-4"
                        >mdi-cake-variant</v-icon
                      >
                      <div class="d-flex flex-column">
                        <h2 class="mt-0 mb-n1">Birthday reward</h2>
                        <span class="darkGrey--text"
                          >{{
                            existingBirthdayReward
                              ? "Configured"
                              : "Not configured"
                          }}<span v-if="existingBirthdayReward">
                            -
                            <a class="brandCyan--text font-weight-bold"
                              >edit</a
                            ></span
                          ></span
                        >
                      </div>
                    </div>
                    <v-icon
                      v-if="!existingBirthdayReward"
                      x-large
                      color="brandCyan"
                      >mdi-plus</v-icon
                    >
                    <v-icon v-else x-large color="success"
                      >mdi-check-circle</v-icon
                    >
                  </div>
                  <div
                    class="cursor-pointer rounded box-border box-border-cyan py-6 px-6 mb-6 reward-box d-flex justify-space-between"
                    @click="loadEditor('SERVICE_ANNIVERSARY')"
                  >
                    <div class="d-flex align-center word-break">
                      <v-icon color="brandCyan" x-large class="mx-4"
                        >mdi-party-popper</v-icon
                      >
                      <div class="d-flex flex-column">
                        <h2 class="mt-0 mb-n1">Anniversary reward</h2>
                        <span class="darkGrey--text"
                          >{{
                            existingServiceAnniversaryReward
                              ? "Configured"
                              : "Not configured"
                          }}<span v-if="existingServiceAnniversaryReward">
                            -
                            <a class="brandCyan--text font-weight-bold"
                              >edit</a
                            ></span
                          ></span
                        >
                      </div>
                    </div>
                    <v-icon
                      v-if="!existingServiceAnniversaryReward"
                      x-large
                      color="brandCyan"
                      >mdi-plus</v-icon
                    >
                    <v-icon v-else x-large color="success"
                      >mdi-check-circle</v-icon
                    >
                  </div>
                  <div v-if="programsWithErrorCount" class="mt-10 mb-4">
                    <h2>
                      <v-icon color="error" x-large>mdi-alert</v-icon>
                      You have {{ programsWithErrorCount }}
                      {{ programsWithErrorCount == 1 ? "reward" : "rewards" }}
                      with issues:
                    </h2>
                    <div
                      class="rounded box-border box-border-grey pt-6 pb-2 px-6 mt-4 d-flex flex-column"
                    >
                      <div
                        v-if="data.birthdayUserCount && existingBirthdayReward"
                        class="d-flex justify-space-between full-width align-start mb-4"
                      >
                        <span class="mt-1">
                          Birthday reward is missing data for
                          <strong>{{
                            formatNumber(data.birthdayUserCount)
                          }}</strong>
                          user{{ data.birthdayUserCount == 1 ? "" : "s" }}
                        </span>
                        <v-btn
                          fab
                          depressed
                          color="error"
                          x-small
                          class="ml-1"
                          @click="loadErrorFlow('BIRTHDAY_USER')"
                          ><v-icon>mdi-chevron-right</v-icon></v-btn
                        >
                      </div>
                      <div
                        v-if="
                          data.serviceUserCount &&
                            existingServiceAnniversaryReward
                        "
                        class="d-flex justify-space-between full-width align-start mb-4"
                      >
                        <span class="mt-1">
                          Anniversary reward is missing data for
                          <strong>{{
                            formatNumber(data.serviceUserCount)
                          }}</strong>
                          user{{ data.serviceUserCount == 1 ? "" : "s" }}
                        </span>
                        <v-btn
                          fab
                          depressed
                          color="error"
                          x-small
                          class="ml-1"
                          @click="loadErrorFlow('ANNIVERSARY_USER')"
                          ><v-icon>mdi-chevron-right</v-icon></v-btn
                        >
                      </div>
                      <div
                        v-if="
                          data.failedBirthdayCount && existingBirthdayReward
                        "
                        class="d-flex justify-space-between full-width align-start mb-4"
                      >
                        <span class="mt-1">
                          Birthday reward has
                          <strong>{{
                            formatNumber(data.failedBirthdayCount)
                          }}</strong>
                          errored reward{{
                            data.failedBirthdayCount == 1 ? "" : "s"
                          }}
                        </span>
                        <v-btn
                          fab
                          depressed
                          color="error"
                          x-small
                          class="ml-1"
                          @click="loadErrorFlow('BIRTHDAY_FAILED')"
                          ><v-icon>mdi-chevron-right</v-icon></v-btn
                        >
                      </div>
                      <div
                        v-if="
                          data.failedServiceCount &&
                            existingServiceAnniversaryReward
                        "
                        class="d-flex justify-space-between full-width align-start mb-4"
                      >
                        <span class="mt-1">
                          Anniversary reward has
                          <strong>{{
                            formatNumber(data.failedServiceCount)
                          }}</strong>
                          errored reward{{
                            data.failedServiceCount == 1 ? "" : "s"
                          }}
                        </span>
                        <v-btn
                          fab
                          depressed
                          color="error"
                          x-small
                          class="ml-1"
                          @click="loadErrorFlow('ANNIVERSARY_FAILED')"
                          ><v-icon>mdi-chevron-right</v-icon></v-btn
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  v-else-if="slideKey === keys.memo"
                  :key="keys.memo"
                  class="full-width text-left"
                >
                  <p class="text-h6 font-weight-bold mb-0 mt-12">
                    Add a note
                  </p>
                  <p class="font-italic mb-5 mt-0 grey--text font-weight-bold">
                    Everyone will see the same note, so keep it generic, but
                    fun!
                  </p>
                  <v-textarea
                    :placeholder="
                      data.rewardType == 'BIRTHDAY'
                        ? 'Enjoy a birthday lunch!'
                        : 'Enjoy a lunch!'
                    "
                    outlined
                    v-model="values.memo"
                    color="brandCyan"
                  ></v-textarea>
                  <p class="text-h6 font-weight-bold mb-0 mt-6">
                    Visibility
                  </p>
                  <div class="d-flex align-center mb-3">
                    <v-radio-group row v-model="values.visibility"
                      ><v-radio
                        value="PRIVATE"
                        label="Private"
                        color="brandCyan"
                      ></v-radio
                      ><v-radio
                        value="PUBLIC"
                        label="Public"
                        color="brandCyan"
                      ></v-radio
                    ></v-radio-group>

                    <v-tooltip top
                      ><template v-slot:activator="{ on, attrs }"
                        ><v-icon v-on="on" v-bind="attrs" class="ml-3 mb-1"
                          >mdi-help-circle-outline</v-icon
                        ></template
                      ><span
                        >If public, a message will be displayed company-wide in
                        the activity feed</span
                      ></v-tooltip
                    >
                  </div>
                </div>
                <div
                  v-else-if="slideKey === keys.celebration"
                  :key="keys.celebration"
                  class="full-width text-left"
                >
                  <p class="text-h6 font-weight-bold mb-5 mt-12">
                    Add some flair to your {{ rewardLanguage }} reward
                  </p>
                  <ElementSelector
                    v-if="loading.budgets || visibleBudgets.length"
                    type="reward"
                    ref="reward-selector"
                    :reviewPage="false"
                    :loading="loading.presets"
                    :cashAward="cashAward"
                    :presets="data.presets"
                    :emoji="values.rewardEmoji"
                    :customEmoji="values.customRewardEmoji"
                    :amount="values.rewardAmount"
                    :customAmount="values.customRewardAmount"
                    @select-reward="selectPaymentPreset"
                    @select-custom-reward="addCustomPreset"
                    @update-custom-amount="values.customRewardAmount = $event"
                    @update-custom-emoji="values.customRewardEmoji = $event"
                    @clear="clearPaymentPreset"
                  ></ElementSelector>
                  <ElementSelector
                    type="gif"
                    ref="gif-selector"
                    :reviewPage="false"
                    :gif="values.gif"
                    :hasImageSupport="false"
                    @select-gif="selectGif"
                    @clear="clearGif"
                  ></ElementSelector>
                </div>
                <div
                  v-else-if="slideKey === keys.review"
                  :key="keys.review"
                  class="full-width text-left pb-4"
                >
                  <div>
                    <p class="text-h6 font-weight-bold mb-1 mt-12">
                      Does this look right?
                    </p>
                    <p class="mb-5">
                      {{ data.userCount }} recipient{{
                        data.userCount == 1 ? "" : "s"
                      }}
                      will receive this on their
                      {{ rewardLanguage }}
                    </p>
                    <!-- <p class="grey--text mb-5">
                      Each recipient will receive this recognition on their
                      {{ rewardLanguage }} as it exists in their profile.
                    </p> -->
                    <p class="mb-0">
                      Users will see: "You just got a happy
                      {{
                        data.rewardType == "BIRTHDAY"
                          ? "birthday"
                          : "anniversary"
                      }}{{
                        values.senderName ? ` from ${values.senderName}` : ``
                      }}!"
                      <a
                        v-if="!dialog.senderName"
                        @click="dialog.senderName = true"
                        class="brandCyan--text"
                        >Change this?</a
                      >
                    </p>

                    <v-expand-transition>
                      <v-form
                        v-if="dialog.senderName"
                        v-model="form.senderName"
                      >
                        <v-text-field
                          label="Sender name"
                          v-model="values.senderName"
                          :rules="formRules.string255Optional"
                          outlined
                          color="brandCyan"
                          class="mt-5"
                        />
                      </v-form>
                    </v-expand-transition>

                    <p class="text-caption mb-1 darkGrey--text mt-5">
                      Memo
                    </p>
                    <v-textarea
                      placeholder="Daniel, I am consistently impressed by your patience with every customer interaction that you face. Your ability to create space for their needs to be heard and calm approach to problem solving allow us to remain a trusted partner to our customers."
                      outlined
                      hide-details
                      v-model="values.memo"
                      color="brandCyan"
                    ></v-textarea>
                  </div>
                  <div class="d-flex align-center mt-2">
                    <v-radio-group row v-model="values.visibility"
                      ><v-radio
                        value="PRIVATE"
                        label="Private"
                        color="brandCyan"
                      ></v-radio
                      ><v-radio
                        value="PUBLIC"
                        label="Public"
                        color="brandCyan"
                      ></v-radio
                    ></v-radio-group>

                    <v-tooltip top
                      ><template v-slot:activator="{ on, attrs }"
                        ><v-icon v-on="on" v-bind="attrs" class="ml-3 mb-1"
                          >mdi-help-circle-outline</v-icon
                        ></template
                      ><span
                        >A public recognition will be displayed company-wide in
                        the Activity Feed</span
                      ></v-tooltip
                    >
                  </div>
                  <ElementSelector
                    v-if="hasReward"
                    type="reward"
                    ref="reward-selector"
                    :reviewPage="true"
                    :loading="loading.presets"
                    :cashAward="cashAward"
                    :presets="data.presets"
                    :emoji="values.rewardEmoji"
                    :customEmoji="values.customRewardEmoji"
                    :amount="values.rewardAmount"
                    :customAmount="values.customRewardAmount"
                    @select-reward="selectPaymentPreset"
                    @select-custom-reward="addCustomPreset"
                    @update-custom-amount="values.customRewardAmount = $event"
                    @update-custom-emoji="values.customRewardEmoji = $event"
                    @clear="clearPaymentPreset"
                  />
                  <ElementSelector
                    v-if="values.gif"
                    type="gif"
                    ref="gif-selector"
                    :reviewPage="true"
                    :gif="values.gif"
                    @select-gif="selectGif"
                    @clear="clearGif"
                  />
                  <div
                    class="d-flex justify-start align-center full-width mt-5"
                    v-if="hasReward"
                  >
                    <p
                      class="review-page-text mt-0 mb-0"
                      :class="{ 'text-xs': $vuetify.breakpoint.xs }"
                    >
                      Reward source:
                    </p>
                    <v-select
                      outlined
                      dense
                      attach
                      :menu-props="{ bottom: true, offsetY: true }"
                      color="brandCyan"
                      :items="visibleBudgets"
                      :loading="loading.budgets"
                      v-model="values.budgetId"
                      item-value="budgetId"
                      item-text="displayNameWithBalance"
                      :no-data-text="
                        loading.budgets
                          ? 'Loading your budgets'
                          : 'No budgets found'
                      "
                      :rules="[v => !!v || 'Budget is required']"
                      class="review-page-field"
                      hide-details
                    >
                      <template v-slot:item="{ item }">
                        <div
                          class="d-flex align-center justify-space-between full-width"
                        >
                          <span
                            :title="item.budgetDisplayName"
                            class="budget-name-container text-overflow-clip"
                          >
                            {{ item.budgetDisplayName }}
                          </span>
                          <span class="text-right word-break"
                            >{{ item.formattedBalance }}
                          </span>
                        </div>
                      </template></v-select
                    >
                  </div>
                  <div
                    v-if="
                      selectedBudget &&
                        selectedBudget.permissions &&
                        selectedBudget.permissions.owner &&
                        hasReward
                    "
                    class="mt-4 full-width d-flex justify-end"
                  >
                    <v-btn
                      text
                      :color="
                        amountRemainingInBudget < 0 ? 'error' : 'brandCyan'
                      "
                      @click="dialog.funding = true"
                    >
                      + Add funds
                    </v-btn>
                  </div>
                  <p
                    v-if="lowBalanceAlertRecommendation"
                    class="darkGrey--text"
                  >
                    We recommend a standalone budget with a low balance alert
                    set to {{ lowBalanceAlertRecommendation }}
                  </p>
                </div>
                <div
                  v-else-if="slideKey === keys.usersMissingData"
                  :key="keys.usersMissingData"
                  class="full-width text-left"
                >
                  <v-btn
                    rounded
                    depressed
                    color="brandCyan"
                    class="white--text mt-6"
                    @click="initializePage()"
                  >
                    <v-icon color="white" class="mr-1"
                      >mdi-arrow-left-circle</v-icon
                    >Back</v-btn
                  >
                  <p class="text-h6 font-weight-bold mb-0 mt-6">
                    The users below will not receive their reward because they
                    are missing {{ rewardLanguage }}s
                  </p>
                  <p class="font-italic mb-5 mt-0 grey--text font-weight-bold">
                    You can update multiple users at once with the CSV upload
                    tool on the People page
                  </p>
                  <p class="font-italic mb-5 mt-0 grey--text font-weight-bold">
                    For each user, once you see the green checkmark, your change
                    is already saved!
                  </p>
                  <p class="font-italic mb-5 mt-0 grey--text font-weight-bold">
                    Note: any users missing a {{ rewardLanguage }} will not
                    receive recognitions
                  </p>
                  <div class="user-container">
                    <div
                      v-for="(user, index) in data.missingDataUsers"
                      :key="user.userId"
                      class="user-row"
                    >
                      <div
                        class="d-flex justify-space-between align-center my-1"
                      >
                        <span class="word-break">
                          {{ user.firstName }} {{ user.lastName }}
                        </span>
                        <div class="d-flex align-center">
                          <v-progress-circular
                            v-if="
                              data.missingDataUsersLoading.find(
                                x => x == user.userId
                              )
                            "
                            indeterminate
                            :size="15"
                            :width="2"
                            color="brandCyan"
                          />
                          <v-icon
                            v-else-if="
                              data.missingDataUsersSuccess.find(
                                x => x == user.userId
                              )
                            "
                            color="success"
                            >mdi-check-circle</v-icon
                          >
                          <input
                            v-if="data.rewardType == 'BIRTHDAY'"
                            class="timestamp-input ml-1"
                            type="date"
                            v-model="user.birthday"
                            @input="updateUser(user, true)"
                          />
                          <input
                            v-else
                            class="timestamp-input ml-1"
                            type="date"
                            v-model="user.jobStartDate"
                            @input="updateUser(user, false)"
                          />
                        </div>
                      </div>
                      <v-divider
                        v-if="index != data.missingDataUsers.length - 1"
                      />
                      <div
                        v-else-if="
                          (data.rewardType == 'BIRTHDAY'
                            ? data.birthdayUserCount
                            : data.serviceUserCount) >
                            data.missingDataUsers.length &&
                            !loading.missingDataUsers
                        "
                        class="d-flex justify-center mb-4"
                      >
                        <v-btn
                          depressed
                          color="brandCyan"
                          class="white--text"
                          @click="
                            if (data.rewardType == 'BIRTHDAY') {
                              getUsersMissingBirthdays(false);
                            } else {
                              getUsersMissingStartDates(false);
                            }
                          "
                          >Load more +</v-btn
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="loading.missingDataUsers"
                    class="d-flex justify-center"
                  >
                    <v-progress-circular
                      indeterminate
                      :size="35"
                      :width="3"
                      color="brandCyan"
                    ></v-progress-circular>
                  </div>
                </div>
                <div
                  v-else-if="slideKey === keys.failedRewards"
                  :key="keys.failedRewards"
                  class="full-width text-left"
                >
                  <v-btn
                    rounded
                    depressed
                    color="brandCyan"
                    class="white--text mt-6"
                    @click="initializePage()"
                  >
                    <v-icon color="white" class="mr-1"
                      >mdi-arrow-left-circle</v-icon
                    >Back</v-btn
                  >
                  <p class="text-h6 font-weight-bold mb-0 mt-6">
                    The users below errored due to insufficient funds. You can
                    manually send payments from here, or elsewhere, and mark
                    them as resolved
                  </p>
                  <!-- <p class="font-italic mb-5 mt-0 grey--text font-weight-bold">
                    You can update multiple users at once with the CSV upload
                    tool on the People page
                  </p> -->
                  <div class="user-container">
                    <div
                      v-for="(user, index) in data.failedPayments"
                      :key="user.parentPaymentRuleConfigFailureId"
                      class="user-row "
                    >
                      <div
                        class="d-flex justify-space-between align-start my-1 py-1"
                      >
                        <span class="word-break">
                          {{
                            devEnvironment
                              ? user.parentPaymentRuleConfigFailureId
                              : ""
                          }}{{ user.firstName }} {{ user.lastName }}
                        </span>
                        <div class="d-flex align-center">
                          <v-progress-circular
                            v-if="
                              data.failedPaymentsLoading.find(
                                x => x == user.parentPaymentRuleConfigFailureId
                              )
                            "
                            indeterminate
                            :size="15"
                            :width="2"
                            color="brandCyan"
                            class="my-2"
                          />
                          <v-icon
                            v-else-if="
                              data.failedPaymentsSuccess.find(
                                x => x == user.parentPaymentRuleConfigFailureId
                              )
                            "
                            color="success"
                            class="my-2"
                            >mdi-check-circle</v-icon
                          >
                          <div v-else class="d-flex flex-column align-end">
                            <div class="d-flex mb-1">
                              <v-btn
                                depressed
                                color="brandCyan"
                                small
                                class="ml-2 white--text"
                                @click="loadPaymentWizard(user)"
                                >Pay now<v-icon
                                  >mdi-chevron-right</v-icon
                                ></v-btn
                              >
                            </div>
                            <a
                              class="brandCyan--text text-caption mt-1"
                              @click="updateFailedPayment(user)"
                              >Mark as resolved</a
                            >
                          </div>
                          <!-- <input
                            class="timestamp-input ml-1"
                            type="date"
                            v-model="user.birthday"
                            @input="updateUserBirthday(user)"
                          /> -->
                        </div>
                      </div>
                      <v-divider
                        v-if="index != data.failedPayments.length - 1"
                      />
                      <div
                        v-else-if="
                          (data.rewardType == 'BIRTHDAY'
                            ? data.failedBirthdayCount
                            : data.failedServiceCount) >
                            data.failedPayments.length &&
                            !loading.failedPayments
                        "
                        class="d-flex justify-center mb-4"
                      >
                        <v-btn
                          depressed
                          color="brandCyan"
                          class="white--text"
                          @click="getFailedPayments(data.rewardType, false)"
                          >Load more +</v-btn
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="loading.failedRewards"
                    class="d-flex justify-center"
                  >
                    <v-progress-circular
                      indeterminate
                      :size="35"
                      :width="3"
                      color="brandCyan"
                    ></v-progress-circular>
                  </div>
                </div>
                <div
                  v-else-if="slideKey == keys.submitting"
                  :key="keys.submitting"
                  class="full-width text-left"
                >
                  <div>
                    <p class="text-h6 font-weight-bold mb-5 mt-12">
                      Processing...
                    </p>
                    <div class="d-flex flex-column align-center"></div>
                  </div>
                </div>
                <div
                  v-else-if="slideKey == keys.sent"
                  :key="keys.sent"
                  class="full-width text-left pt-12"
                >
                  <p class="text-h6 word-break font-weight-bold mb-3">
                    You just made someone's day better!
                  </p>
                  <!-- <p class="full-width">
                Your payments are being processed. You will be notified once
                completed.
              </p> -->
                  <div class="d-flex justify-center mt-6">
                    <Robin
                      :height="120"
                      :width="140"
                      class=""
                      :loop="false"
                      animation="backflip"
                    />
                  </div>
                  <div class="d-flex justify-center mt-12 mb-5">
                    <v-btn
                      depressed
                      rounded
                      color="brandCyan"
                      class="white--text"
                      @click="initializePage()"
                      width="95"
                      ><span>Close</span></v-btn
                    >
                  </div>
                </div>
                <div
                  v-else-if="slideKey == keys.error"
                  :key="keys.error"
                  class="full-width text-left pt-12"
                >
                  <p class="text-h6 word-break font-weight-bold mb-3">
                    We had some trouble sending your payment. <br />Please try
                    again later.
                  </p>
                  <p class="full-width">
                    If this problem continues, feel free to reach out to
                    customer service via one of the following methods...
                  </p>
                  <p class="pl-4 full-width">
                    Email -
                    <b
                      ><a href="mailto:help@wewhistle.com"
                        >help@wewhistle.com</a
                      ></b
                    >
                  </p>
                  <p class="pl-4 full-width">
                    Phone (Toll Free) - <b>(855) 264-3329</b>
                  </p>

                  <div class="d-flex justify-center my-5">
                    <v-btn
                      depressed
                      rounded
                      color="brandCyan"
                      class="white--text mt-4"
                      @click="(slideKey = keys.review), (progressBarValue = 0)"
                      ><v-icon class="mr-1">mdi-chevron-left</v-icon
                      ><span style="margin-top:1px;">Go back</span></v-btn
                    >
                  </div>
                </div>
              </transition>
            </div>
          </v-col>
        </v-row>
        <v-overlay v-if="loading.existing || dialog.funding" />
        <!-- Budget funding -->
        <v-navigation-drawer
          v-model="dialog.funding"
          temporary
          fixed
          right
          width="500"
          disable-route-watcher
        >
          <StripePaymentMethodsManager
            v-if="dialog.funding"
            @reset="dialog.funding = false"
            @get-budgets="getBudgets"
            :destinationBudget="selectedBudget"
            :budgets="data.budgets"
            :clientId="clientId"
            source="ANNUAL_REWARD_WIZARD"
            :displayBudgetTransfer="false"
          />
        </v-navigation-drawer>
      </v-card>
    </v-dialog>

    <!-- Error dialog -->
    <v-dialog v-model="dialogError" width="500">
      <v-card rounded="0" class="d-flex justify-center flex-column pa-6">
        <div class="d-flex justify-center align-center mx-2 mb-5">
          <v-icon color="error" x-large class="exit-warning-icon mr-2"
            >mdi-alert</v-icon
          >
          <v-card-title
            class="word-break text-left exit-warning-text mx-0 px-0"
          >
            There was an error creating your {{ rewardLanguage }} reward
          </v-card-title>
        </div>

        <p>{{ errorText }}</p>

        <v-card-actions class="mx-12">
          <!-- <v-btn
            color="primary"
            @click="closeProgramBuilder"
            outlined
            width="130"
            >Don't save</v-btn
          > -->
          <v-btn
            class="mx-auto white--text"
            depressed
            rounded
            color="brandCyan"
            @click="
              (slideKey = keys.review),
                (dialogError = false),
                (progressBarValue = 0)
            "
            width="130"
            >Go Back</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog used for displaying loading message. -->
    <LoadingDialog
      v-if="loading.existing"
      :showDialog="true"
      :displayRobin="false"
      header="Loading"
      line1="Please wait..."
    ></LoadingDialog>
    <!-- Error dialog -->
    <!-- <v-dialog v-model="dialog.reset" width="500"> -->
    <ErrorDialog
      v-if="dialog.reset"
      :centerText="true"
      :title="`Are you sure you want to ${dialog.close ? 'close' : 'reset'}?`"
      subtitle="All current changes will be lost"
      button1Text="Cancel"
      button1Width="140"
      @button1="
        dialog.reset = false;
        dialog.close = false;
      "
      button2Text="Ok"
      button2Width="140"
      @button2="
        if (dialog.close) {
          reset();
        } else {
          initializePage();
        }
      "
      @close="dialog.reset = false"
    />
  </div>
</template>

<script>
import BudgetService from "@/services/BudgetService";
import TriggerService from "@/services/TriggerService";
import WalletService from "@/services/WalletService";
import UserService from "@/services/UserService";

import { formRules } from "@/shared_data/data";

import Robin from "@/components/Robin";
import LoadingDialog from "@/components/loadingDialog";
import ErrorDialog from "@/components/ErrorDialog";
import PillNav from "@/components/PillNav";
import LottiePlayer from "@/components/LottiePlayer";
import ElementSelector from "@/components/recognition/ElementSelector";
import StripePaymentMethodsManager from "@/components/stripe/StripePaymentMethodsManager.vue";

import currency from "currency.js";
import moment from "moment";
import { mapState } from "vuex";

// import { debounce } from "@/shared_data/functions";

function initialState() {
  return {
    slideKey: 1,
    dialogError: false,
    errorText: null,
    slideDirection: "topic-left",
    keys: {
      home: 1,
      memo: 2,
      celebration: 3,
      review: 4,
      usersMissingData: 5,
      failedRewards: 7,
      submitting: 101,
      sent: 102,
      error: 103
    },
    loading: {
      existing: false,
      submitting: false,
      users: false,
      groups: false,
      recent: false,
      budgets: false,
      presets: false,
      missingDataUsers: false,
      failedRewards: false
    },
    data: {
      errorFlow: null,
      preloadedType: null,
      rewardType: null,
      existingRewards: [],
      presets: [],
      budgets: [],
      userCount: 0,
      birthdayUserCount: 0,
      serviceUserCount: 0,
      missingDataUsers: [],
      missingDataUsersLoading: [],
      missingDataUsersSuccess: [],
      // Used to keep track of total distinct users that we updated. This is so we can get the correct offset when we hit the API
      globalMissingDataUsersSuccess: [],
      failedBirthdayCount: 0,
      failedServiceCount: 0,
      failedPayments: [],
      failedPaymentsLoading: [],
      failedPaymentsSuccess: []
    },
    dialog: {
      reset: false,
      close: false,
      funding: false,
      senderName: false
    },
    lottie: {
      particle: false
    },
    values: {
      budgetId: null,
      paymentPresetId: null,
      celebrationId: 1,
      senderName: null,
      preset: null,
      amount: null,
      memo: null,
      visibility: null,
      gif: null,
      rewardEmoji: null,
      rewardAmount: null,
      customRewardEmoji: null, //"💰",
      customRewardAmount: null,
      companyValues: []
    },
    form: { senderName: true },
    formRules,
    progressBarValue: 0
  };
}

export default {
  name: "AnnualRewardWizard",
  title: "Annual Reward Wizard | Whistle",
  components: {
    Robin,
    StripePaymentMethodsManager,
    LoadingDialog,
    ErrorDialog,
    PillNav,
    LottiePlayer,
    ElementSelector
  },
  props: {
    type: {
      type: String,
      default: null
    },
    previousRoute: {
      type: String,
      default: "paymentadmin"
    },
    previousRouteProps: {
      type: Object,
      default: undefined
    }
  },
  data() {
    return initialState();
  },
  created() {
    // this.data.preloadedType = this.type;
    this.initializePage(true);
  },
  mounted() {
    //Move the fresh works help widget
    var freshworks = document.querySelector("#launcher-frame");
    if (freshworks) {
      freshworks.style.right = "-55px";
      freshworks.style["max-width"] = freshworks.style["min-width"] = "90px";
    }
  },
  destroyed() {
    var freshworks = document.querySelector("#launcher-frame");
    if (freshworks) {
      freshworks.style.right = "22px";
    }
  },
  beforeDestroy() {},
  methods: {
    initializePage(created = false) {
      console.log("Created annual wizard", created);
      this.resetAllData();
      if (created) this.data.preloadedType = this.type;
      this.getExistingAnnualRewards();
      this.getPaymentPresets();
      this.getBudgets();
      this.getUserCount();

      let clientName;
      if (
        this.userProfile &&
        this.userProfile.Client &&
        this.userProfile.Client.clientName
      )
        clientName = this.userProfile.Client.clientName;
      if (this.userProfile && this.userProfile.clientId == 1) {
        const client = this.clients.find(x => x.clientId == this.clientId);
        if (client) clientName = client.clientName;
      }

      this.values.senderName = clientName;
      this.createPlatformActivity("OPENED_ANNUAL_REWARD_WIZARD");
    },
    loadEditor(type) {
      console.log("Loading editor ", type);
      if (
        (type == "BIRTHDAY" && this.existingBirthdayReward) ||
        (type == "SERVICE_ANNIVERSARY" && this.existingServiceAnniversaryReward)
      ) {
        console.log("Editing/replacing reward");
        const item =
          type == "BIRTHDAY"
            ? this.existingBirthdayReward
            : this.existingServiceAnniversaryReward;
        const {
          memo,
          budgetId,
          visibility,
          emoji,
          amount,
          mediaUrl,
          celebrationId,
          senderName
        } = item;
        this.values.memo = memo;
        this.values.budgetId = budgetId;
        this.values.visibility = visibility;
        this.values.rewardEmoji = emoji;
        this.values.rewardAmount = amount;
        this.values.gif = mediaUrl;
        this.values.celebrationId = celebrationId;
        this.values.senderName = senderName;
      }

      this.data.rewardType = type;
      this.slideKey = this.keys.memo;
    },
    confirmPageReset(close = false) {
      // Ignore any button clicks while it's submitting
      if (this.slideKey > this.keys.review && !close) return;
      this.dialog.reset = true;
      this.dialog.close = close;
    },
    formatCurrency(amount, cash = true) {
      return currency(amount).format({ symbol: cash ? "$" : "" });
    },
    formatNumber(amount) {
      return currency(amount, { precision: 0, symbol: "" }).format();
    },
    resetAllData() {
      // Used to reset the state when the user clicks out of the payments console.
      Object.assign(this.$data, initialState());
    },
    reset() {
      if (this.previousRoute)
        this.$router.push({
          name: this.previousRoute,
          params: this.previousRouteProps || {}
        });
      else {
        this.resetAllData();
        this.$router.push("/paymentadmin");

        // this.$emit("reset");
      }
    },
    scrollToTop() {
      document.getElementsByClassName(
        "v-dialog v-dialog--active"
      )[0].scrollTop = 0;
    },
    goToSlide(index) {
      console.log("goToSlide ", index);
      // Figures out what step we're going into so we can insert the proper platform activity
      var platformActivityEvent = null;
      // We have to block if the user has already sent payment
      if (this.slideKey >= 1 && this.slideKey < this.keys.submitting) {
        if (index < this.slideKey) {
          this.slideDirection = "topic-right";
          this.scrollToTop();
          return (this.slideKey = index);
        } else {
          this.slideDirection = "topic-left";
        }

        // We route depending on true's and false's in the computed array
        var foundFalse = false;
        // Index is relative to all. We have to equate it to the index in our current route;
        var routeIndex = this.route.findIndex(x => x == index);

        // When using goToSlide to jump around, we have to make sure the user has completed all steps prior to the step that they're trying to route to.
        for (var i = 0; i < routeIndex; i++) {
          if (!this.routeValidation[i]) {
            foundFalse = true;
          }
        }

        console.log({ routeIndex, foundFalse });
        // If we only found true values in the array, then we didn't find a false and therefore can continue
        if (!foundFalse) {
          this.scrollToTop();
          this.slideKey = index;

          if (platformActivityEvent)
            this.createPlatformActivity(platformActivityEvent);
        }
      }
    },
    goToNextSlide() {
      this.slideDirection = "topic-left";
      this.scrollToTop();

      if (this.slideKey === this.keys.review) {
        //We're on the final slide so show confirmation
        //Or we are editing and the message has already sent, so they can only update the message body
        this.playParticleEffect();
        this.saveReward();
      } else if (this.slideKey == this.keys.sent) {
        this.initializePage();
        // this.reset();
      } else {
        // Figures out what step we're going into so we can insert the proper platform activity
        var platformActivityEvent = null;
        if (this.slideKey == this.keys.recipient)
          platformActivityEvent = "SELECTED_BUDGET_RECIPIENTS";
        else if (this.slideKey == this.keys.memo) {
          platformActivityEvent = "ADDED_BUDGET_MEMO";
        } else if (this.slideKey == this.keys.payment)
          platformActivityEvent = "SELECTED_BUDGET_AMOUNT";

        // The route has been established. Now we have to check if they can proceed
        var routeIndex = this.route.findIndex(x => x == this.slideKey);

        // if (
        //   this.route[routeIndex + 1] == this.keys.mccCustom &&
        //   this.slideProvisional.selectedPresets.length > 0
        // ) {
        //   // We can skip custom MCC page
        //   routeIndex++;
        // }
        this.playParticleEffect();
        this.slideKey = this.route[routeIndex + 1];

        if (platformActivityEvent)
          this.createPlatformActivity(platformActivityEvent);
      }
    },
    goToPreviousSlide() {
      this.slideDirection = "topic-right";
      this.scrollToTop();

      // The route has been established. Now we have to check if they can proceed
      var routeIndex = this.route.findIndex(x => x == this.slideKey);
      var counter = 1;

      this.slideKey = this.route[routeIndex - counter];
    },
    async saveReward() {
      // Show loading bar
      console.log("Submitting! ", this.keys.submitting);
      const startTime = new Date();
      this.slideKey = this.keys.submitting;
      this.loading.submitting = true;
      const progressIncrement = 2;
      const sleepDur = 75;
      this.loadBarSleeper(progressIncrement, sleepDur, false);

      try {
        var object = {
          clientId: this.clientId,
          budgetId: this.values.budgetId,
          memo: this.computedNote,
          type: this.data.rewardType,
          amount: this.values.rewardAmount
            ? currency(this.values.rewardAmount)
            : null,
          emoji: this.values.rewardEmoji,
          mediaUrl: this.values.gif,
          visibility: this.values.visibility,
          celebrationId: this.values.celebrationId || 1,
          senderName: this.values.senderName,
          audience: {
            groupId: [0]
          }
        };

        console.log(object);
        // Display loading screen
        // var response;
        var response =
          this.editingReward && this.editingRewardId
            ? await WalletService.replacePaymentConfigV2(
                this.editingRewardId,
                object,
                this.magicLinkToken
              )
            : await WalletService.createPaymentConfigV2(
                object,
                this.magicLinkToken
              );

        const minTimeout = 1250;
        let timeDiff = moment().diff(moment(startTime));
        if (timeDiff > minTimeout) {
          timeDiff = minTimeout;
        }
        setTimeout(() => {
          this.loading.submitting = false;
          this.loadBarSleeper(progressIncrement, sleepDur, true);
          console.log(response);

          // Display success screen
          this.slideKey = this.keys.sent;
        }, minTimeout - timeDiff);

        // })
      } catch (error) {
        console.log("Error upserting annual reward ", error);
        if (error && error.error_code && typeof error.error_code == "string") {
          if (error.error_code == "1070100") {
            this.dialogError = true;
            this.errorText =
              "The total amount is more than the selected budget's balance.";
            return;
          } else if (error.error_code == "1070024") {
            this.dialogError = true;
            this.errorText = "The reward amount is invalid.";
            return;
          } else if (error.error_code == "1070072") {
            this.dialogError = true;
            this.errorText = "We had trouble using the selected reward source.";
            return;
          }
        } else if (error && error.error) {
          this.slideKey = this.keys.error;
          return;
        }
        this.dialogError = true;
        this.errorText =
          "An unknown error occurred. Please try again and contact your Whistle representative if this issue persists.";
      }
    },
    loadBarSleeper(incr, dur, override = false) {
      setTimeout(() => {
        console.log("Incrementing progress", this.progressBarValue);
        if (!this.loading.submitting || override) {
          // We just want to skip to the end
          this.progressBarValue = 100;
        } else {
          this.progressBarValue += incr;
          // We loop IF no error has occurred
          if (
            this.progressBarValue < 90 &&
            this.slideKey != this.keys.error &&
            !this.dialogError
          )
            this.loadBarSleeper(incr, dur);
        }
      }, dur);
    },
    refreshClientId(clientId) {
      this.clientId = clientId;

      this.initializePage();
    },
    preloadBudget() {
      // Preload the master budget
      if (this.values.budgetId) return;
      const clientBudgets = this.data.budgets.filter(
        x =>
          x.clientId == this.clientId &&
          // x.awardId == 1 &&
          x.status == "Active" &&
          Number(x.budgetBalance) > 0
      );

      const acctBalance = clientBudgets.find(x => x.masterBudget);
      const cashBudget = clientBudgets.find(x => x.awardId == 1);
      if (acctBalance) this.values.budgetId = acctBalance.budgetId;
      else if (cashBudget) this.values.budgetId = cashBudget.budgetId;
      else if (clientBudgets.length)
        this.values.budgetId = clientBudgets[0].budgetId;
    },
    createPlatformActivity(event) {
      var obj = {
        userId: this.userProfile.userId,
        clientId: this.userProfile.clientId,
        event: event,
        source: "BUDGET_WIZARD",
        category: "PAYMENTS",
        date: new Date()
      };
      TriggerService.createActivity(obj, this.magicLinkToken)
        .then(response => {
          console.log("Response from inserting activity ", response);
        })
        .catch(error => {
          console.log("There was an error inserting activity ", error);
        });
    },
    async getExistingAnnualRewards() {
      const startTime = new Date();
      try {
        this.loading.existing = true;
        let result = await WalletService.getParentPaymentRuleConfigsV2(
          {
            filter: `clientId = ${this.clientId} && status = 'Active'`
          },
          this.magicLinkToken
          // 2
        );
        console.log("Get annual rewards ", result);
        this.data.existingRewards = result.rows;
      } catch (e) {
        console.log("Error getting annual rewards ", e);
      } finally {
        const minTimeout = 1000;
        let timeDiff = moment().diff(moment(startTime));
        if (timeDiff > minTimeout) {
          timeDiff = minTimeout;
        }
        setTimeout(() => {
          this.loading.existing = false;
        }, minTimeout - timeDiff);

        // Now we preload if they're preloading a type and it exists
        // We want to wait a tick so the computed properties get a chance to update
        this.$nextTick(() => {
          const type = this.data.preloadedType;
          console.log({ type });

          if (
            (type == "BIRTHDAY" && !this.existingBirthdayReward) ||
            (type == "SERVICE_ANNIVERSARY" &&
              !this.existingServiceAnniversaryReward)
          ) {
            // console.log("Preload ", type);
            this.loadEditor(type);
          }
          if (this.existingBirthdayReward) {
            this.getFailedPayments("BIRTHDAY", true);
            this.getUsersMissingBirthdays(true);
          }
          if (this.existingServiceAnniversaryReward) {
            this.getFailedPayments("SERVICE_ANNIVERSARY", true);
            this.getUsersMissingStartDates(true);
          }
        });
      }
    },
    async getBudgets() {
      try {
        this.loading.budgets = true;
        let response = await BudgetService.getBudgets(
          {
            lite: 1,
            clientId: this.clientId
          },
          // this.magicLinkToken,
          2
        );
        console.log("Get budgets ", response);
        this.data.budgets = response;
        this.preloadBudget();
      } catch (e) {
        console.log("Error getting budgets ", e);
      } finally {
        this.loading.budgets = false;

        // Clears any reward if we didn't find any budgets
        if (
          !this.data.budgets.length ||
          !this.data.budgets.find(x => x.budgetBalance > 0)
        ) {
          this.values.rewardAmount = null;
          this.values.rewardEmoji = null;
          this.values.budgetId = null;
        }
      }
    },
    async getPaymentPresets() {
      try {
        this.loading.presets = true;

        let results = await WalletService.getRecognitionPaymentPresetsV2(
          this.clientId,
          "annual-reward-wizard",
          this.magicLinkToken
        );
        console.log("Got payment presets ", results.result.rows);
        this.data.presets = results.result.rows.filter(
          x => x.status != "Disabled"
        );
      } catch (err) {
        console.log("Error with payment presets ", err);
      } finally {
        this.loading.presets = false;
      }
    },
    async getUserCount() {
      try {
        let results = await UserService.getUsersV2(
          {
            filter: `clientId = ${this.clientId} && status = "Active"`,
            extract: "userId",
            limit: 1
          },
          this.magicLinkToken
        );
        console.log("Got user count ", results.result.rows);
        this.data.userCount = results.result.count;
      } catch (err) {
        console.log("Error with getting user count ", err);
      }
    },
    async getUsersMissingBirthdays(count = false) {
      try {
        this.loading.missingDataUsers = true;

        let results = await UserService.getUsersV2(
          {
            filter: `clientId = ${this.clientId} && birthday = null && status = "Active"`,
            extract: count ? "userId" : "userId,firstName,lastName,birthday",
            limit: count ? 1 : this.devEnvironment ? 5 : 40,
            offset: count
              ? 0
              : this.data.missingDataUsers.length -
                this.data.globalMissingDataUsersSuccess.length,
            sort: "createDate ASC"
          },
          this.magicLinkToken
        );
        console.log("Got users without birthdays ", results.result.rows);

        if (!count) {
          results.result.rows.forEach(row => {
            if (!this.data.missingDataUsers.find(x => x.userId == row.userId))
              this.data.missingDataUsers.push(row);
            else console.log("Ignoring user");
          });
        } else this.data.birthdayUserCount = results.result.count;
      } catch (err) {
        console.log("Error with getting birthday-less users ", err);
      } finally {
        this.loading.missingDataUsers = false;
      }
    },
    async getUsersMissingStartDates(count = false) {
      try {
        this.loading.missingDataUsers = true;

        let results = await UserService.getUsersV2(
          {
            filter: `clientId = ${this.clientId} && jobStartDate = null && status = "Active"`,
            extract: count
              ? "userId"
              : "userId,firstName,lastName,jobStartDate",
            limit: count ? 1 : this.devEnvironment ? 5 : 40,
            offset: count
              ? 0
              : this.data.missingDataUsers.length -
                this.data.globalMissingDataUsersSuccess.length,
            sort: "createDate ASC"
          },
          this.magicLinkToken
        );
        console.log("Got users without job start dates ", results.result.rows);

        if (!count) {
          results.result.rows.forEach(row => {
            if (!this.data.missingDataUsers.find(x => x.userId == row.userId))
              this.data.missingDataUsers.push(row);
            else console.log("Ignoring user");
          });
        } else this.data.serviceUserCount = results.result.count;
      } catch (err) {
        console.log("Error with getting start date-less users ", err);
      } finally {
        this.loading.missingDataUsers = false;
      }
    },
    async getFailedPayments(type, count = false) {
      try {
        console.log({ type, count });
        const id =
          type == "BIRTHDAY"
            ? this.existingBirthdayReward.parentPaymentRuleConfigId
            : this.existingServiceAnniversaryReward.parentPaymentRuleConfigId;
        this.loading.failedRewards = true;

        let result = await WalletService.getParentPaymentRuleConfigFailuresV2(
          {
            filter: `parentPaymentRuleConfigId = ${id} && status = "Failed"`,
            extract: count ? "userId" : undefined,
            limit: count ? 1 : this.devEnvironment ? 5 : 40,
            expand: "User",
            offset: count
              ? 0
              : this.data.failedPayments.length -
                this.data.failedPaymentsSuccess.length,
            sort: "createDate ASC"
          },
          this.magicLinkToken
        );
        console.log("Got users that had failed payments ", result.rows);

        if (!count) {
          result.rows.forEach(row => {
            if (
              !this.data.failedPayments.find(
                x =>
                  x.parentPaymentRuleConfigFailureId ==
                  row.parentPaymentRuleConfigFailureId
              )
            )
              this.data.failedPayments.push({
                ...row,
                firstName: row.User.firstName,
                lastName: row.User.lastName
              });
            else console.log("Ignoring user");
          });
        } else {
          if (type == "BIRTHDAY") this.data.failedBirthdayCount = result.count;
          else this.data.failedServiceCount = result.count;
        }
      } catch (err) {
        console.log("Error with getting start date-less users ", err);
      } finally {
        this.loading.failedRewards = false;
      }
    },
    async updateUser(user, birthday = true) {
      try {
        console.log("updateUser", user.userId);
        this.data.missingDataUsersSuccess = this.data.missingDataUsersSuccess.filter(
          x => x != user.userId
        );
        if ((birthday && user.birthday) || (!birthday && user.jobStartDate)) {
          const date = moment(birthday ? user.birthday : user.jobStartDate);
          if (
            !(
              date.isValid() &&
              date.isAfter(moment().subtract(100, "year")) &&
              ((birthday && date.isBefore(moment())) ||
                (!birthday && date.isBefore(moment().add(1, "year"))))
            )
          ) {
            console.log("Invalid date. Returning early");
            return;
          }

          this.data.missingDataUsersLoading.push(user.userId);
          let response = await UserService.updateUserV2(user.userId, {
            birthday: birthday ? date.format("YYYY-MM-DD") : undefined,
            jobStartDate: !birthday ? date.format("YYYY-MM-DD") : undefined
          });
          console.log("Updated user's date ", {
            userId: user.userId,
            date,
            response
          });
          this.data.missingDataUsersSuccess.push(user.userId);
          this.data.globalMissingDataUsersSuccess.push(user.userId);
          this.data.globalMissingDataUsersSuccess = [
            ...new Set(this.data.globalMissingDataUsersSuccess)
          ];
        }
      } catch (err) {
        console.log("Error updating birthday user ", err);
      } finally {
        this.data.missingDataUsersLoading = this.data.missingDataUsersLoading.filter(
          x => x != user.userId
        );
      }
    },
    async updateFailedPayment(row) {
      const key = row.parentPaymentRuleConfigFailureId;
      try {
        console.log("updateFailedPayment", key);
        this.data.failedPaymentsSuccess = this.data.failedPaymentsSuccess.filter(
          x => x != key
        );

        this.data.failedPaymentsLoading.push(key);
        let response = await WalletService.updateParentPaymentRuleConfigFailuresV2(
          key,
          {
            status: "Resolved"
          }
        );
        console.log("Marked as resolved ", {
          key,
          response
        });
        this.data.failedPaymentsSuccess.push(key);
        this.data.failedPaymentsSuccess.push(key);
        this.data.failedPaymentsSuccess = [
          ...new Set(this.data.failedPaymentsSuccess)
        ];
      } catch (err) {
        console.log("Error marking payment as resolved ", err);
      } finally {
        this.data.failedPaymentsLoading = this.data.failedPaymentsLoading.filter(
          x => x != key
        );
      }
    },
    selectPaymentPreset(preset) {
      this.values.rewardAmount = preset.amount;
      this.values.rewardEmoji = preset.emoji;
    },
    clearPaymentPreset() {
      this.values.rewardEmoji = null;
      this.values.rewardAmount = null;
      this.values.customRewardEmoji = null;
    },
    addCustomPreset() {
      if (!this.values.customRewardEmoji) this.values.customRewardEmoji = "💰";
      this.values.rewardEmoji = this.values.customRewardEmoji;
      this.values.rewardAmount = Number(this.values.customRewardAmount);
    },
    selectGif(gifEvent) {
      this.values.gif = gifEvent;
    },
    clearGif() {
      this.values.gif = null;
    },
    playParticleEffect() {
      this.lottie.particle = true;
      setTimeout(() => {
        this.lottie.particle = false;
      }, 1000);
    },
    loadErrorFlow(type) {
      if (type.includes("BIRTHDAY")) this.data.rewardType = "BIRTHDAY";
      else this.data.rewardType = "SERVICE_ANNIVERSARY";
      this.data.errorFlow = type;
      this.goToNextSlide();
      if (type == "BIRTHDAY_USER") this.getUsersMissingBirthdays(false);
      else if (type == "ANNIVERSARY_USER")
        this.getUsersMissingStartDates(false);
      else if (type == "BIRTHDAY_FAILED")
        this.getFailedPayments(this.data.rewardType, false);
      else if (type == "ANNIVERSARY_FAILED")
        this.getFailedPayments(this.data.rewardType, false);
    },
    loadPaymentWizard(user) {
      const reward =
        this.data.rewardType == "BIRTHDAY"
          ? this.existingBirthdayReward
          : this.existingServiceAnniversaryReward;
      const routeData = this.$router.resolve({
        name: "recognitionwizard",
        query: {
          sourcePage: "annualrewardwizard",
          userId: user.userId,
          budgetId: reward.budgetId,
          memo:
            this.data.rewardType == "BIRTHDAY"
              ? "Sorry we left our wallet at home on your birthday, better late than never! Hope your birthday was great!" //"Sorry for the delay in your birthday reward. Hope it was great!"
              : "Sorry we left our wallet at home on your anniversary, better late than never! Hope your anniversary was great!",
          senderName: reward.senderName,
          // budgetId: reward.budgetId,
          emoji: reward.emoji,
          amount: reward.amount,
          clientId: reward.clientId,
          mediaUrl: reward.mediaUrl,
          visibility: "PRIVATE"
        }
      });
      // this.$router.push({
      //   name: "paymentwizard",
      //   params: {
      //     userId: user.userId
      //   }
      // });
      window.open(routeData.href, "_blank");
    }
  },
  computed: {
    ...mapState([
      "userProfile",
      "clients",
      "permissions",
      "globalClientId",
      "magicLinkToken"
    ]),
    clientId: {
      get: function() {
        return this.globalClientId;
      },
      set: function(newVal) {
        this.$store.dispatch("setClientId", newVal);
      }
    },
    disableContinueButton: {
      cache: false,
      get: function() {
        // Compare to route validation
        // We check the index within the route that we're in and use routeValidation to see if we've satisfied the requirements
        var routeIndex = this.route.findIndex(x => x == this.slideKey);
        return !this.routeValidation[routeIndex];
      }
    },
    visibleBudgets() {
      // var awardId = null;
      const budgetArr = this.data.budgets.filter(x => {
        return (
          x.status == "Active" &&
          x.clientId == this.clientId &&
          (Number(x.allocatedBalance) > 0 || Number(x.budgetBalance) > 0)
        );
      });
      return budgetArr;
    },
    selectedBudget: {
      cache: false,
      get: function() {
        if (this.values.budgetId !== null) {
          var budget = this.data.budgets.find(
            x => x.budgetId == this.values.budgetId
          );

          return budget;
        }
        return null;
      }
    },
    cashAward() {
      return !(this.selectedBudget && this.selectedBudget.awardId != 1);
    },
    singularAwardUnit() {
      return this.selectedBudget &&
        this.selectedBudget.awardId != 1 &&
        this.selectedBudget.Award
        ? this.selectedBudget.Award.unitSingular || "unit"
        : null;
    },
    pluralAwardUnit() {
      return this.selectedBudget &&
        this.selectedBudget.awardId != 1 &&
        this.selectedBudget.Award
        ? this.selectedBudget.Award.unitPlural || "units"
        : null;
    },
    amountRemainingInBudget() {
      if (this.selectedBudget && this.selectedBudget.budgetBalance) {
        return currency(this.selectedBudget.budgetBalance);
      } else {
        return 0;
      }
    },
    amountRemainingInBudgetFormatted() {
      return `${currency(this.amountRemainingInBudget).format({
        symbol: this.cashAward ? "$" : ""
      })} ${
        this.cashAward
          ? ""
          : this.amountRemainingInBudget == 1
          ? this.singularAwardUnit
          : this.pluralAwardUnit
      }`;
    },
    lowBalanceAlertRecommendation() {
      if (!this.data.userCount || !this.hasReward || !this.selectedBudget)
        return null;
      const amt = currency(this.data.userCount, { precision: 0 })
        .multiply(this.values.rewardAmount)
        .divide(10);
      console.log({ amt });
      if (amt.value == 0) return null;
      return amt.format({ symbol: this.cashAward ? "$" : "" });
    },
    pillNavSteps() {
      return [
        {
          step: this.keys.home,
          icon: "home",
          active: this.confirmPageReset
        },
        {
          step: this.keys.memo,
          icon: "message-text"
        },
        {
          step: this.keys.celebration,
          icon: "gift-outline"
        },
        {
          step: this.keys.review,
          icon: "rocket-launch"
        }
      ];
    },
    route: {
      cache: false,
      get: function() {
        // We establish the route depending on what payment type
        // This dynamic array of keys will be used by routeValidation, disableContinuButton, etc
        if (
          this.data.errorFlow == "BIRTHDAY_USER" ||
          this.data.errorFlow == "ANNIVERSARY_USER"
        )
          return [this.keys.home, this.keys.usersMissingData];
        if (
          this.data.errorFlow == "BIRTHDAY_FAILED" ||
          this.data.errorFlow == "ANNIVERSARY_FAILED"
        )
          return [this.keys.home, this.keys.failedRewards];
        return [
          this.keys.home,
          this.keys.memo,
          this.keys.celebration,
          this.keys.review,
          this.keys.submitting,
          this.keys.sent
        ];
      }
    },
    routeValidation: {
      cache: false,
      get: function() {
        var array = [];

        // This uses this.route and compiles an array of true/false values for their entire route to tell us if they've completed everything that they need to
        // Used by disableContinueButton and goToSlide
        this.route.forEach(page => {
          // Page 1
          // console.log(page);
          if (page == this.keys.home && !this.loading.existing) {
            // console.log("sent");
            array.push(true);
          } else if (
            page == this.keys.memo &&
            this.values.memo &&
            this.values.memo.length &&
            this.values.visibility
          ) {
            array.push(true);
          } else if (page == this.keys.celebration) {
            array.push(true);
          } else if (
            page == this.keys.review &&
            this.values.memo &&
            this.values.memo.length &&
            this.form.senderName &&
            // Don't let them proceed if budgets haven't loaded and they have a reward
            (!this.hasReward || (this.hasReward && !this.loading.budgets))
            //  &&
            // this.amountRemainingInBudget >= 0
          ) {
            array.push(true);
          } else if (page == this.keys.submitting) {
            array.push(false);
          } else if (page == this.keys.sent) {
            // console.log("sent");
            array.push(true);
          } else {
            // console.log("ELSE");
            array.push(false);
          }
          // console.log(array[array.length - 1]);
        });

        // console.log(array);
        return array;
      }
    },
    computedNote() {
      if (!this.values.memo) return null;
      // let arr = [];
      // if (this.values.memo) {
      //   this.values.memo
      //     .split("\n")
      //     .forEach(item => arr.push(`${item.trim()}<br />`));
      // }
      // return arr.join("");
      return this.values.memo;
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    formattedAmount() {
      return currency(this.values.amount).value;
    },
    totalAmount() {
      return currency(this.values.amount).value;
    },
    formattedTotalPayment() {
      let v = this.totalAmount;
      return `${currency(v).format({ symbol: this.cashAward ? "$" : "" })} ${
        this.cashAward
          ? ""
          : v == 1
          ? this.singularAwardUnit
          : this.pluralAwardUnit
      }`;
    },
    rewardLanguage() {
      return this.data.rewardType == "SERVICE_ANNIVERSARY"
        ? "service anniversary"
        : "birthday";
    },
    computedHeaderTitle() {
      if (this.slideKey == this.keys.home)
        return "Configure Birthday or Service Anniversary";

      return `Configure your ${this.rewardLanguage} reward`;
    },
    computedHomePageHeader() {
      if (
        this.loading.existing ||
        (!this.existingBirthdayReward && !this.existingServiceAnniversaryReward)
      )
        return "You have no birthday or service anniversary rewards configured. Start one now!";
      else if (
        this.existingBirthdayReward &&
        this.existingServiceAnniversaryReward
      )
        return "You have birthday and service anniversary rewards configured. Edit now!";
      else if (!this.existingBirthdayReward)
        return "You have no birthday reward configured. Add one now!";
      else
        return "You have no service anniversary reward configured. Add one now!";
    },
    existingBirthdayReward() {
      return this.data.existingRewards.find(x => x.type == "BIRTHDAY");
    },
    existingServiceAnniversaryReward() {
      return this.data.existingRewards.find(
        x => x.type == "SERVICE_ANNIVERSARY"
      );
    },
    editingReward() {
      return !!(
        (this.data.rewardType == "BIRTHDAY" && this.existingBirthdayReward) ||
        (this.data.rewardType == "SERVICE_ANNIVERSARY" &&
          this.existingServiceAnniversaryReward)
      );
    },
    editingRewardId() {
      if (this.existingBirthdayReward && this.data.rewardType == "BIRTHDAY")
        return this.existingBirthdayReward.parentPaymentRuleConfigId;
      else if (
        this.existingServiceAnniversaryReward &&
        this.data.rewardType == "SERVICE_ANNIVERSARY"
      )
        return this.existingServiceAnniversaryReward.parentPaymentRuleConfigId;
      return null;
    },
    hasReward() {
      return !!(this.values.rewardEmoji && this.values.rewardAmount);
    },
    programsWithErrorCount() {
      let num = 0;
      if (
        this.existingBirthdayReward &&
        (this.data.birthdayUserCount || this.data.failedBirthdayCount)
      )
        num++;
      if (
        this.existingServiceAnniversaryReward &&
        (this.data.serviceUserCount || this.data.failedServiceCount)
      )
        num++;
      return num;
    },
    devEnvironment() {
      return !!(
        process.env.VUE_APP_ENVIRONMENT == "dev" ||
        process.env.VUE_APP_ENVIRONMENT == "test"
      );
    }
  },
  watch: {
    budgets: function(newVal, oldVal) {
      if (oldVal.length == 0) {
        console.log("Got budget watcher", oldVal);

        this.preloadBudget();
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Code for transitions between slides */
.topic-left-enter {
  opacity: 0;
  transform: translateX(100%);
}

.topic-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.topic-left-enter-active,
.topic-right-enter-active {
  transition: all 0.65s cubic-bezier(0.19, 1, 0.22, 1);
}

/* outline used for the main content card */
.main-card {
  border: solid 1px;
  border-color: lightgray;
  min-height: calc(100vh - 120px);
}

.budget-name-container {
  width: calc(100% - 120px);
}

/* Hides extra padding that for some reason exists in v-row */
.container-row {
  overflow-x: hidden;
}

/* Sets width for amt field */
.amount-text-field >>> .v-input__slot {
  max-width: 150px;
  width: 150px;
  min-width: 150px;
}

.review-page-field {
  width: 65% !important;
  min-width: 170px !important;
  max-width: 400px !important;
  margin-left: 12px;
}
.review-page-text {
  width: 35% !important;
}

/* Home page borders */
.box-border {
  border: 1px solid lightgray;
}
.box-border-green {
  border-color: var(--v-brandDarkGreen-base);
}
.box-border-cyan {
  border-color: var(--v-brandCyan-base);
}
.box-border-grey {
  border-color: grey;
}

/* Box on home page to show configurations */
.reward-box:hover {
  background-color: #eeeeee;
}

.lottie-container {
  position: relative;
}
.lottie-particle-effect {
  position: absolute;
  top: -105px;
  left: -77px;
  /* z-index: 1; */
  pointer-events: none;
}

.preset-emoji {
  font-size: 40px;
}
</style>
