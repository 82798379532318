import axios from "../axios";

const baseUrl = process.env.VUE_APP_DEV_LOCAL
  ? process.env.VUE_APP_CONTENT_URL
  : process.env.VUE_APP_GKE == "2"
  ? process.env.VUE_APP_WHISTLE_API.replace(
      "MICROSERVICE",
      "microservice-content"
    )
  : `${process.env.VUE_APP_WHISTLE_API}${
      process.env.VUE_APP_GKE ? "/microservice-content" : "/content-service"
    }`;

class ContentService {
  downloadContent(id, filepath) {
    return axios
      .get(baseUrl + "/content/download/" + id, {
        responseType: "blob"
      })
      .then(response => {
        console.log(response.data);
        var blob = new Blob([response.data], {
          type: "application/octet-stream"
        });

        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        var fileName = filepath.substring(filepath.lastIndexOf("/") + 1);
        console.log(filepath);
        console.log(fileName);
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }

  createContent(attachment, version = 1) {
    let formData = new FormData();
    formData.append("name", attachment.name);
    formData.append("description", attachment.description);
    formData.append("category", attachment.category);
    formData.append("fileName", attachment.fileName);
    formData.append("fileSize", attachment.fileSize);
    formData.append(
      "type",
      attachment.type != undefined ? attachment.type : null
    );
    formData.append(
      "generateSignedUrl",
      attachment.generateSignedUrl != undefined
        ? attachment.generateSignedUrl
        : false
    );
    //only attach the file if it's not a video, but sending up a video will ruin your life
    if (attachment.type != null && attachment.type != "video") {
      formData.append("file", attachment.file);
    }

    if (attachment.clientId !== undefined) {
      formData.append("clientId", attachment.clientId);
    }
    console.log(baseUrl, attachment);
    return axios
      .post(baseUrl + "/content", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(response => {
        console.log(response);
        return response.data.content;
      })
      .catch(error => {
        console.log("Error!", error);
        if (version == 1) return error;
        else throw error;
      });
  }

  createPublicContent(file, context) {
    let formData = new FormData();
    formData.append('Image', file);
    formData.append('context', context);

    return axios
      .post(baseUrl + '/v2/content/public', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log('Error!', error);
        throw error;
      });
  }

  getAllContent(version = 1) {
    return axios
      .get(baseUrl + "/content")
      .then(response => {
        return response.data.content;
      })
      .catch(error => {
        console.log("Error!", error.response);

        if (version == 1) return error;
        else throw error;
      });
  }
  getContent(contentId) {
    return axios
      .get(baseUrl + "/content/" + contentId)
      .then(response => {
        return response.data.content;
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }
  updateContent(contentId, attachment) {
    let formData = new FormData();
    formData.append("name", attachment.name);
    formData.append("description", attachment.description);
    formData.append("category", attachment.category);
    formData.append("clientId", attachment.clientId);
    formData.append("file", attachment.file);
    return axios
      .patch(baseUrl + "/content/" + contentId, formData)
      .then(response => {
        console.log(response);
        return response.data.success;
      })
      .catch(error => {
        console.log("Error!", error);
        return error;
      });
  }
  deleteContent(contentId, clientId) {
    return axios
      .delete(baseUrl + "/content/ " + clientId + "/" + contentId)
      .then(response => {
        console.log(response);
        return response.data.success;
      })
      .catch(error => {
        console.log("Error!", error);
        return error;
      });
  }

  deleteContentByFilePath(filePath, clientId) {
    var path = encodeURIComponent(filePath);
    return axios
      .delete(baseUrl + "/content/ " + clientId + "/filePath/" + path)
      .then(response => {
        console.log(response);
        return response.data.success;
      })
      .catch(error => {
        console.log("Error!", error);
        return error;
      });
  }

  uploadContentWithSignedUrl(url, attachment, version = 1) {
    let formData = new FormData();

    formData.append("file", attachment);
    return axios
      .put(url, attachment, {
        headers: {
          "Content-Type": "application/octet-stream"
        }
      })
      .then(response => {
        console.log(response);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error);
        if (version == 1) return error;
        else throw error;
      });
  }

  encodeFile(url, type, version = 1) {
    return axios
      .post(baseUrl + "/content/encode", {
        url: url,
        type: type
      })
      .then(response => {
        // console.log(response);
        return response.data.jobId;
      })
      .catch(error => {
        console.log("Error!", error);
        if (version == 1) return error;
        else throw error;
      });
  }

  refreshFileEncodingStatus() {
    return axios
      .get(baseUrl + "/content/encode/status")
      .then(response => {
        // console.log(response);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error);
        return error;
      });
  }

  getSignedUrlBasedOnContentId(contentId, version = 1) {
    return axios
      .get(baseUrl + "/content/signedUrl/" + contentId)
      .then(response => {
        // console.log(response);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error);
        if (version == 1) return error;
        else throw error;
      });
  }

  getSignedUrlForViewingContent(url, version = 1) {
    console.log(baseUrl + "/" + url);
    return axios
      .get(baseUrl + "/" + url)
      .then(response => {
        // console.log(response);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error);
        if (version == 1) return error;
        else throw error;
      });
  }
}

export default new ContentService();
