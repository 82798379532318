var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"cursor-pointer rounded box-border box-border-green py-8 px-6 mb-5 hover-box",class:{
      'box-border-green': !_vm.gifSelector,
      'box-border-cyan': _vm.gifSelector
    },on:{"click":function($event){_vm.drawer = true}}},[(!_vm.hasReward && !_vm.hasGIF)?_c('div',{staticClass:"d-flex align-center word-break clear-fab-container"},[_c('v-icon',{staticClass:"mr-4",attrs:{"color":_vm.gifSelector ? 'brandCyan' : 'brandDarkGreen',"x-large":""}},[_vm._v(_vm._s(_vm.gifSelector ? "mdi-party-popper" : "mdi-gift-outline"))]),_c('h2',{staticClass:"mt-1"},[_vm._v(" "+_vm._s(_vm.gifSelector ? "Add a celebration" : "Add a reward")+" ")]),_c('div',{staticClass:"clear-fab"},[_c('div',{staticClass:"lottie-container"},[(_vm.lottie.poof)?_c('div',{staticClass:"lottie-poof-effect"},[_c('LottiePlayer',{attrs:{"animation":"poof","height":150,"width":150,"loop":false}})],1):_vm._e()])])],1):_c('div',{staticClass:"d-flex align-center justify-space-between clear-fab-container"},[_c('v-card-title',{staticClass:"pl-0 word-break"},[_vm._v(_vm._s(_vm.gifSelector ? "Selected celebration" : "Selected reward")+":")]),_c('div',{staticClass:"d-flex justify-start align-center lottie-container"},[(_vm.lottie.star)?_c('div',{class:{
            'lottie-reward-star-effect': !_vm.gifSelector,
            'lottie-gif-star-effect': _vm.gifSelector
          }},[_c('LottiePlayer',{attrs:{"animation":"stars","height":250,"width":250,"speed":1.8,"loop":false}})],1):_vm._e(),(!_vm.gifSelector)?_c('span',{staticClass:"preset-emoji mr-2"},[_vm._v(_vm._s(_vm.emoji))]):_vm._e(),(!_vm.gifSelector)?_c('h3',{},[_vm._v(" "+_vm._s(_vm.formatCurrency(_vm.amount, _vm.cashAward))+" ")]):_vm._e(),_c('div',[(_vm.gif)?_c('v-img',{attrs:{"src":_vm.gif,"max-width":"100","contain":""}}):_vm._e()],1)]),_c('v-btn',{staticClass:"elevation-0 clear-fab",attrs:{"fab":"","color":"grey","x-small":"","dark":""},on:{"click":function($event){$event.stopPropagation();return _vm.clearElement.apply(null, arguments)}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)],1)]),_c('v-navigation-drawer',{attrs:{"temporary":"","fixed":"","right":"","width":"500"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[(_vm.drawer && !_vm.gifSelector)?_c('RewardDrawer',{attrs:{"loading":_vm.loading,"cashAward":_vm.cashAward,"presets":_vm.presets,"customEmoji":_vm.customEmoji,"customAmount":_vm.customAmount},on:{"close":function($event){_vm.drawer = false},"select-reward":_vm.selectReward,"select-custom-reward":_vm.selectCustomReward,"update-custom-amount":_vm.updateCustomAmount,"update-custom-emoji":_vm.updateCustomEmoji}}):(_vm.drawer && _vm.gifSelector)?_c('CelebrationSelector',{attrs:{"hasImageSupport":_vm.hasImageSupport,"autoGifSelector":!_vm.hasImageSupport},on:{"close":_vm.close,"select-celebration":_vm.handleCelebration}}):_vm._e()],1),(_vm.drawer)?_c('v-overlay'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }