<template>
  <div class="celebration-selector">
    <v-card flat>
      <v-toolbar dark color="brandCyan" rounded="0" elevation="0">
        <v-btn dark icon @click="close">
          <v-icon large>mdi-close-circle</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-toolbar-title class="d-flex align-center">
          <v-icon class="mr-2 mt-n1">mdi-party-popper</v-icon>
          Choose a celebration
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <div class="px-4 pt-4">
        <div 
          class="cursor-pointer rounded box-border py-8 px-6 mb-5 hover-box"
          @click="openGifDrawer"
        >
          <div class="d-flex align-center">
            <v-icon color="brandCyan" x-large class="mr-4">mdi-party-popper</v-icon>
            <h2 class="mt-1">Choose a GIF</h2>
          </div>
        </div>

        <div 
          class="cursor-pointer rounded box-border py-8 px-6 mb-5 hover-box"
          @click="openPhotoDrawer"
          :class="{'disabled': !hasImageSupport}"
        >
          <div class="d-flex align-center">
            <v-icon color="brandCyan" x-large class="mr-4">mdi-upload</v-icon>
            <h2 class="mt-1">Upload a Photo</h2>
          </div>
        </div>
      </div>

      <!-- GIF Drawer -->
      <v-navigation-drawer
        v-model="gifDrawerOpen"
        temporary
        fixed
        right
        width="500"
      >
        <v-toolbar dark color="brandCyan" rounded="0" elevation="0">
          <v-btn dark icon @click="close">
            <v-icon large>mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title>Select a GIF</v-toolbar-title>
        </v-toolbar>
        
        <GIFDrawer
          v-if="gifDrawerOpen"
          @close="gifDrawerOpen = false"
          @select-gif="selectGIF"   
        />
      </v-navigation-drawer>

      <!-- Photo Drawer -->
      <v-navigation-drawer
        v-model="photoDrawerOpen"
        temporary
        fixed
        right
        width="500"
      >
        <v-toolbar dark color="brandCyan" rounded="0" elevation="0">
          <v-btn dark icon @click="close">
            <v-icon large>mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title>Upload a Photo</v-toolbar-title>
        </v-toolbar>

        <div class="pa-4">
          <ImageSelector
            v-if="photoDrawerOpen"
            v-model="selectedImageUrl"
            :autoOpen="true"
            :aspectRatioValue="1"
            @update-file="handleImageFile"
            @update-url="handleImageUrl"
          />
          
          <v-btn
            v-if="selectedImageUrl && selectedImageFile"
            color="brandCyan"
            class="white--text mt-4 d-block ml-auto"
            @click="saveImage"
            rounded
            depressed
          >
            Save
          </v-btn>
        </div>
      </v-navigation-drawer>

    </v-card>
  </div>
</template>

<script>
import GIFDrawer from './GIFDrawer.vue'
import ImageSelector from '@/components/ImageSelector.vue'

export default {
  name: 'CelebrationSelector',
  components: {
    GIFDrawer,
    ImageSelector
  },
  props: {
    hasImageSupport: {
      type: Boolean,
      default: true
    },
    autoGifSelector: {
      type: Boolean,
      default: false 
    }
  },
  watch: {
    autoGifSelector: {
      immediate: true,
      handler(val) {
        if (val && !this.hasImageSupport) {
          this.gifDrawerOpen = true;

        }
      }
    }
  },
  data() {
    return {
      gifDrawerOpen: false,
      photoDrawerOpen: false,
      selectedImageUrl: null,
      selectedImageFile: null
    }
  },
  methods: {
    close() {
      // Reset selected image and file
      this.selectedImageUrl = null
      this.selectedImageFile = null
      if (this.autoGifSelector || !this.gifDrawerOpen && !this.photoDrawerOpen) {
        this.$emit('close')

      }
      if (this.gifDrawerOpen) {
        this.gifDrawerOpen = false
      } else if (this.photoDrawerOpen) {
        this.photoDrawerOpen = false
      }
      
      


    },
    openGifDrawer() {
      this.gifDrawerOpen = true
    },
    openPhotoDrawer() {
      this.photoDrawerOpen = true
    },
    selectGIF(gif) {
      const url = gif.images?.["fixed_height"]?.url
      this.$emit('select-celebration', {
        type: 'gif',
        url
      })
    },
    handleImageFile(file) {
      this.selectedImageFile = file
     
    },
    handleImageUrl(url) {
      this.selectedImageUrl = url
   
    },
    saveImage() {
      this.$emit('select-celebration', {
        type: 'image',
        url: this.selectedImageUrl,
        file: this.selectedImageFile 
      })
      this.photoDrawerOpen = false
    }
  }
}
</script>

<style scoped>
.celebration-selector {
  height: 100%;
}

.box-border {
  border: 1px solid lightgray;
}

.box-border-cyan {
  border-color: var(--v-brandCyan-base);
}

.hover-box:hover {
  background-color: #eeeeee;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
