<template>
  <div>
    <v-menu max-width="260" offset-x offset-y absolute>
      <template v-slot:activator="{ on, attrs }">
        <div
          class="d-flex align-center full-width image-container"
          v-bind="attrs"
          v-on="on"
          @drop.prevent="imageFileChanged($event, true)"
          @dragover.prevent
        >
          <v-img
            v-if="imageUrl"
            :lazy-src="imageUrl"
            :src="imageUrl"
            :key="imageUrl"
            :aspect-ratio="aspectRatioValue"
            width="100%"
            class="box-border-global rounded-lg cursor-pointer"
          >
            <template v-slot:placeholder>
              <div
                class="px-6 rounded-lg grey-background text-left full-height full-width"
              >
                <p class="pt-6">
                  Click here to add a feature graphic - this only appears in
                  emails and the Whistle platform (JPG or PNG)
                </p>
                <p class="error--text" v-if="invalidFileType">
                  Invalid file type
                </p>
              </div>
            </template></v-img
          >
          <div
            v-else
            class="px-6 py-5 rounded-lg grey-background text-left full-height full-width rounded-lg cursor-pointer box-border-global"
          >
            <p class="ma-0">
              Click here to add a feature graphic - this only appears in emails
              and the Whistle platform (JPG or PNG)
            </p>
            <p class="error--text ma-0 mt-2" v-if="invalidFileType">
              Invalid file type
            </p>
          </div>
        </div>
      </template>
      <v-list>
       
        <v-list-item @click="uploadImage">
          <v-list-item-title class="primary--text align-text-left"
            ><v-icon class="mr-2" color="black">mdi-upload</v-icon>Upload from
            computer</v-list-item-title
          >
        </v-list-item>
        <!-- <v-list-item @click="slide1.dialogImageUrl = true">
          <v-list-item-title class="primary--text align-text-left"
            ><v-icon class="mr-4" color="black">mdi-link</v-icon>By
            URL</v-list-item-title
          >
        </v-list-item> -->
        <v-list-item @click="resetState">
          <v-list-item-title class="primary--text align-text-left"
            ><v-icon color="black" class="mr-2">mdi-delete</v-icon>Remove
            file</v-list-item-title
          >
        </v-list-item>
      </v-list>
    </v-menu>
    <input
          id="uploader"
          ref="uploader"
          class="d-none"
          type="file"
          accept="image/*"
          @change="imageFileChanged($event, false)"
          @blur="imageFileChanged($event, false)"
        />
    <ImageCropper
      v-if="cropper"
      :fileName="fileName"
      :imageUrl="cropUrl"
      :aspectRatioValue="aspectRatioValue"
      :aspectRatioText="aspectRatioText"
      @close="resetState"
      @success="saveCroppedImage"
    />
  </div>
</template>

<script>
import ImageCropper from "./ImageCropper.vue";
export default {
  name: "ImageSelector",
  components: { ImageCropper },
  props: {
    value: {},
    aspectRatioValue: {
      type: Number,
      default: 16 / 9
    },
    aspectRatioText: {
      type: String
      // default: "16 : 9"
    },
    autoOpen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    var d = {
      cropper: false,
      loading: false,
      imageFile: null,
      fileName: null,
      cropUrl: null,
      invalidFileType: false
    };
    return d;
  },
  created() {
    console.log("Created ImageSelector");
  },
  mounted() {
    // Only auto-trigger if autoOpen is true and no value exists
    this.$nextTick(() => {
      if (this.$refs.uploader && !this.value && this.autoOpen) {
        this.$refs.uploader.click()
      }
    })
  },
  methods: {
    reset() {
      this.$emit("close");
      this.display = false;
    },
    imageFileChanged(e, dragAndDrop = false) {
      this.invalidFileType = false;
      const file = dragAndDrop
        ? e?.dataTransfer?.files?.[0]
        : e?.target?.files?.[0];
      if (file) {
        const { type } = file;
        if (!type?.startsWith("image/")) {
          this.invalidFileType = true;
          return console.error("Invalid file type");
        }
        this.fileName = file.name;
        this.cropUrl = URL.createObjectURL(file);
        //This wipes the files in the uploader component so it detects a change even if you select the same image twice
        document.querySelector("#uploader").value = "";
        this.cropper = true;
      }
    },
    resetState() {
      this.fileName = null;
      this.imageFile = null;
      this.cropUrl = null;
      this.cropper = false;
      this.imageUrl = null;
      this.invalidFileType = false;

      this.$emit("update-url", null);
      this.$emit("update-file", null);
    },
    uploadImage() {
      // this.slide1.selectingImageFlag = true;
      // window.addEventListener(
      //   "focus",
      //   () => {
      // this.slide1.selectingImageFlag = false;
      //   },
      //   { once: true }
      // );

      this.$refs.uploader.click();
    },
    saveCroppedImage(e) {
      console.log("saveCroppedImage", { e });
      this.resetState();
      const { file, imageUrl } = e;
      this.imageFile = file;
      this.imageUrl = imageUrl;
      this.$emit("update-url", imageUrl);
      this.$emit("update-file", file);
    },
    closeImageCropDialog(submitPressed) {
      //submitPressed is so we can use one function for both dialog buttons
      //If they clicked submit, submitPressed is true
      //If they clicked cancel, submitPressed is false
      if (submitPressed) {
        const { canvas } = this.$refs.cropper.getResult();
        this.slide1.imageURL = canvas.toDataURL();
        canvas.toBlob(blob => {
          // Do something with blob: upload to a server, download and etc.
          var file = new File([blob], this.slide1.imageURLFileName);
          this.slide1.image = file;

          //Regardless, we close dialog
          this.slide1.dialogImageCropper = false;
          this.slide1.imageURLToCrop = null;
          this.slide1.imageURLFileName = null;
        });
      } else {
        //Regardless, we close dialog
        this.slide1.dialogImageCropper = false;
        this.slide1.imageURLToCrop = null;
        this.slide1.imageURLFileName = null;
      }
    }
  },
  computed: {
    imageUrl: {
      get: function() {
        return this.value;
      },
      set: function(newVal) {
        this.$emit("input", newVal);
      }
    }
  },
  watch: {
    display: function(newVal) {
      if (!newVal) this.$emit("close");
    }
  }
};
</script>

<style></style>
